import React, { useState } from "react"
import Button from "./Button"

export default function ArticlesTag({ id, children, background }) {
  const uniqueLabels = [
    ...new Set(children.map(c => c.props.label).filter(label => label)),
  ]

  const [option, setOption] = useState(
    uniqueLabels.length > 2 ? uniqueLabels[0] : null
  )
  const [isSelected, setIsSelected] = useState(0)
  let rendering = option
    ? children.filter(c => c.props.label === option)
    : children

  const handleClick = (e, index) => {
    setIsSelected(index)
    setOption(e)
  }

  return (
    <div className="flex flex-wrap justify-center">
      {option && (
        <div className="w-full flex flex-col lg:flex-row justify-center">
          {uniqueLabels.map((t, index) => (
            <div key={t} className="lg:mx-2 mx-8 flex justify-center">
              <Button
                //id={index}
                variant={"default2"}
                onClick={() => handleClick(t, index)}
                className={`button w-full ${
                  isSelected === index ? `${background}` : "default"
                }`}
                faq={true}
              >
                {t}
              </Button>
            </div>
          ))}
        </div>
      )}
      {rendering}
    </div>
  )
}

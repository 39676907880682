import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/UI/Layout"
import Title from "../components/UI/Title"
import Button from "../components/UI/Button"
import Text from "../components/UI/Text"
import SideBySide from "../components/UI/SideBySide"
import Photo from "../components/UI/Photo"
import Photos from "../components/UI/Photos"
import Offices from "../components/Offices"
import Team from "../components/Team"
import Spacer from "../components/UI/Spacer"
import ClientsWall from "../components/ClientsWall"
import PhotoWall from "../components/UI/PhotoWall"
import ImageWall from "../components/UI/ImageWall"
import HelpWall from "../components/UI/HelpWall"
import Reviews from "../components/UI/Reviews"
import Iframe from "../components/UI/Iframe"
import Feature from "../components/UI/Feature"
import Form from "../components/Form"
import Carousel from "../components/UI/Carousel"
import Sliders from "../components/UI/Sliders"
import Steps from "../components/UI/Steps"
import PageOrProductPage from "../components/UI/PageOrProductPage"
import Article from "../components/UI/Article"
import ArticlesTag from "../components/UI/ArticlesTag"

export function mapDynamicComponent(component, index, locale, type, category) {
  if (!component) return

  const key = `${component.id}-${component.__typename}`
  const backgroundColor = type === "BTC" ? "bg-pink" : "bg-blue"

  switch (component.__typename) {
    case "STRAPI__COMPONENT_UI_TITLE":
      return (
        <Title
          id={`section-${index}`}
          key={key}
          level={component.level}
          className={component.lessWideTitle && "m-4 lg:m-8"}
        >
          {component.content}
          {component.secondary && (
            <>
              <br />
              <div className="font-light inline">{component.secondary}</div>
            </>
          )}
        </Title>
      )
    case "STRAPI__COMPONENT_UI_BUTTON":
      return (
        <div
          className={`w-full flex m-2 ${
            {
              left: "justify-start",
              middle: "justify-center",
              right: "justify-end",
            }[component.align || "middle"]
          }`}
        >
          <Button
            id={`section-${index}`}
            key={key}
            href={component.url}
            variant={component.variant}
            style={{}}
          >
            {component.label}
          </Button>
        </div>
      )
    case "STRAPI__COMPONENT_UI_TEXT":
      return (
        <Text
          id={`section-${index}`}
          key={key}
          className={component.lessWide ? "lg:px-40 m-4 lg:m-8" : "m-4 lg:m-8"}
        >
          {component.text.data.text}
        </Text>
      )
    case "STRAPI__COMPONENT_UI_SPACER":
      return <Spacer id={`section-${index}`} key={key} size={component.size} />
    case "STRAPI__COMPONENT_PAGE_OFFICES":
      return (
        <Offices id={`section-${index}`} key={key}>
          <Button id={`section-${index}`} key={key}>
            {"Découvrir"}
          </Button>
        </Offices>
      )
    case "STRAPI__COMPONENT_PAGE_TEAM":
      return (
        <div
          className="relative flex flex-col text-center items-center my-3 lg:my-6 lg:pb-10 lg:pt-8"
          id={`section-${index}`}
        >
          <Title
            level={component.teamTitle.level}
            class={component.teamTitle.lessWideTitle && "lg:px-40"}
          >
            {component.teamTitle.content}
          </Title>
          <Team id={`section-${index}`} key={key} />
        </div>
      )
    case "STRAPI__COMPONENT_PAGE_CLIENTS":
      return <ClientsWall id={`section-${index}`} key={key} className="my-4" />
    case "STRAPI__COMPONENT_PAGE_REVIEWS":
      return (
        <div
          className="relative flex justify-center items-center lg:-mx-5 my-3 lg:my-6 lg:pb-10 lg:pt-8"
          id={`section-${index}`}
        >
          <div
            className={`absolute lg:block h-full w-screen opacity-30 ${backgroundColor}`}
            style={{ zIndex: -1, clipPath: "url(#backgroundClip)" }}
          />
          <Reviews
            id={`section-${index}`}
            key={key}
            reviews={component.reviews}
          />
        </div>
      )
    /*case "STRAPI__COMPONENT_UI_PHOTO":
      return (
        <div key={key} className={component.lessWide && "lg:px-40"}>
          <Photo
            id={`section-${index}`}
            image={component.photo}
            caption={component.caption.data.caption}
            allowTransparency
          />
        </div>
      )*/
    case "STRAPI__COMPONENT_UI_PHOTO_GALLERY":
      return (
        <PhotoWall
          id={`section-${index}`}
          key={key}
          photos={component.photos}
        />
      )
    case "STRAPI__COMPONENT_PAGE_FAQ":
      return (
        <HelpWall
          id={`section-${index}`}
          helps={component.helps}
          faqContent={component.faqContent}
          title={component.title}
          layout={component.layout}
          background={backgroundColor}
        />
      )
    case "STRAPI__COMPONENT_UI_IFRAME":
      return (
        <Iframe
          id={`section-${index}`}
          url={component.url}
          size={component.pageSize}
          ratio={component.ratio}
        />
      )
    case "STRAPI__COMPONENT_UI_PAGE_GALLERY":
      const isArticle =
        component.pages.filter(p => p.category === "article").length !== 0

      return (
        <div
          id={`section-${index}`}
          key={key}
          className="flex flex-wrap justify-center -mx-2 lg:-mx-5"
        >
          <div className="flex justify-center flex-wrap w-full">
            {(component.pages.filter(p => p.category !== "article") || [])
              .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
              .map(page => (
                <PageOrProductPage
                  key={page.id}
                  page={page}
                  id={component.id}
                  layout={component.pageLayout}
                  locale={locale}
                  category={category}
                />
              ))}
          </div>

          {(component.products || [])
            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
            .map(product => (
              <PageOrProductPage
                key={product.id}
                product={product}
                id={component.id}
                layout={component.productLayouts}
                locale={locale}
                category={category}
              />
            ))}

          <div>
            {isArticle && (
              <ArticlesTag background={backgroundColor}>
                {component.pages
                  .filter(
                    p => p.category === "article" && p.label !== "" && p.label
                  )
                  .sort(
                    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                  )
                  .map(page => (
                    <Article
                      key={`${page.id}-xyz`}
                      label={page.label}
                      page={page}
                      //id={component.id}
                      locale={locale}
                      category={category}
                    ></Article>
                  ))}
              </ArticlesTag>
            )}
          </div>
        </div>
      )

    case "STRAPI__COMPONENT_UI_TEXT_PHOTO":
      return (
        <SideBySide
          id={`section-${index}`}
          key={key}
          background={component.Background}
          type={backgroundColor}
          layout={component.layout}
          style={component.style}
          left={
            component.layout === "photo_left" ? (
              <Photos
                images={component?.images}
                className={`${
                  !component.style && `rounded-t-3xl lg:rounded-l-3xl`
                } lg:rounded-t-none bg-cover bg-center h-screen/3 lg:h-full`}
                parentId={`section-${index}`}
              />
            ) : (
              <div className={"m-4 lg:m-8 text-left"}>
                {component.title && (
                  <Title
                    level={component.title.level}
                    className={`text-left ${
                      component.Background && "text-white"
                    }`}
                  >
                    {component.title.content}
                    {component.title.secondary && (
                      <>
                        <br />
                        <div className="font-light inline">
                          {component.title.secondary}
                        </div>
                      </>
                    )}
                  </Title>
                )}
                <Text>{component.textPhotoText?.text.data.text}</Text>
                {component.buttons && (
                  <div className="flex flex-col lg:flex-row items-center justify-center">
                    {component.buttons.map(button => (
                      <Button
                        key={button.label}
                        href={button.url}
                        variant={button.variant}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            )
          }
          right={
            component.layout === "photo_left" ? (
              <div className="m-4 lg:m-8 text-left">
                {component.title && (
                  <Title
                    level={component.title.level}
                    className={`text-left ${
                      component.Background && "text-white"
                    }`}
                  >
                    {component.title.content}
                    {component.title.secondary && (
                      <>
                        <br />
                        <div className="font-light inline">
                          {component.title.secondary}
                        </div>
                      </>
                    )}
                  </Title>
                )}
                <Text>{component.textPhotoText?.text.data.text}</Text>
                {component.buttons && (
                  <div className="flex flex-col lg:flex-row items-center justify-center">
                    {component.buttons.map(button => (
                      <Button
                        key={button.label}
                        className="my-2"
                        href={button.url}
                        variant={button.variant}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <Photos
                images={component.images}
                className={`${
                  !component.style && `rounded-t-3xl lg:rounded-r-3xl`
                } lg:rounded-t-none bg-cover bg-center h-screen/3 lg:h-full`}
                parentId={`section-${index}`}
              />
            )
          }
        ></SideBySide>
      )
    case "STRAPI__COMPONENT_PAGE_FEATURES":
      return (
        <div
          className="relative flex flex-col items-center lg:-mx-5 my-3 lg:my-6 lg:pb-10 lg:pt-8"
          id={`section-${index}`}
        >
          <div
            className={`top-0 absolute lg:block h-full w-screen opacity-30 ${backgroundColor}`}
            style={{ zIndex: -1, clipPath: "url(#backgroundClip)" }}
          />
          {component.title && (
            <Title level={component.title.level} className={`text-left`}>
              {component.title.content}
              {component.title.secondary && (
                <>
                  <br />
                  <div className="font-light inline">
                    {component.title.secondary}
                  </div>
                </>
              )}
            </Title>
          )}
          {component.features && (
            <Sliders>
              {component.features.map(f => (
                <Feature
                  key={f.label}
                  //image={getImageBySize(f.attributes.logo, "small")}
                  description={f.featureDescription}
                  label={f.label}
                ></Feature>
              ))}
            </Sliders>
          )}
        </div>
      )
    case "STRAPI__COMPONENT_PAGE_FORM":
      return (
        <div
          className="flex flex-wrap justify-center items-start my-2"
          id={`section-${index}`}
        >
          <Form
            locale={locale}
            defaultType={
              component.type === "pro" ? "professional" : "individual"
            }
            inline
          />
        </div>
      )
    case "STRAPI__COMPONENT_UI_CAROUSEL":
      return (
        <div className="h-screen/test overflow-hidden" id={`section-${index}`}>
          <div className="absolute inset-x-0 m-auto h-screen/test">
            <Carousel
              title={component.title}
              locale={locale}
              key={key}
              type={type}
              images={
                <>
                  {component.carouselPhotos && (
                    <Photos
                      images={component.carouselPhotos}
                      className={"bg-cover bg-center"}
                      parentId={`section-${index}`}
                      active={true}
                    />
                  )}
                </>
              }
            />
          </div>
        </div>
      )
    case "STRAPI__COMPONENT_UI_STEPS":
      return (
        <Steps
          id={`section-${index}`}
          key={key}
          block={component.Block}
          type={type}
        ></Steps>
      )
    case "STRAPI__COMPONENT_UI_IMAGE_GALLERY":
      return (
        <ImageWall
          id={`section-${index}`}
          key={key}
          photos={component.image}
        ></ImageWall>
      )
  }
}

const Page = ({ data }) => {
  const {
    sections,
    path,
    title,
    description,
    linkedData,
    locale,
    category,
    type,
  } = data.page
  return (
    <>
      <Layout
        title={title}
        description={description}
        path={path}
        linkedData={linkedData}
        locale={locale}
        landing={category}
        type={type}
      >
        {sections.map((section, index) =>
          mapDynamicComponent(section, index, locale, type, category)
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    page: strapiPage(id: { eq: $id }) {
      name
      title
      description
      path
      locale
      category
      type
      sections {
        ... on STRAPI__COMPONENT_PAGE_CLIENTS {
          id
        }
        ... on STRAPI__COMPONENT_PAGE_OFFICES {
          id
        }
        ... on STRAPI__COMPONENT_PAGE_FEATURES {
          id
          title {
            content
            secondary
            level
          }
          features {
            label
            name
            featureDescription {
              data {
                featureDescription
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_TEAM {
          id
          teamTitle: title {
            content
            lessWide
            level
            secondary
          }
        }
        ... on STRAPI__COMPONENT_PAGE_FORM {
          id
          type
        }
        ... on STRAPI__COMPONENT_PAGE_REVIEWS {
          id
          reviews {
            id
            name
            title
            score
            url
            date
            review {
              data {
                review
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_SPACER {
          id
          size
        }
        ... on STRAPI__COMPONENT_UI_TEXT {
          id
          lessWide
          text {
            data {
              text
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_TITLE {
          id
          content
          secondary
          level
          lessWideTitle: lessWide
        }
        ... on STRAPI__COMPONENT_UI_BUTTON {
          id
          label
          url
          variant
          align
        }
        ... on STRAPI__COMPONENT_UI_PAGE_GALLERY {
          id
          pageLayout: layout
          pages {
            cover {
              alternativeText
              ext
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
            path
            category
            label
            locale
            title
            updatedAt
            description
            id
            createdAt
            createdBy {
              data {
                attributes {
                  firstname
                  lastname
                }
              }
            }
          }
          id
          productLayouts: layout
          products {
            name
            path
            catchphrase
            locale
            updatedAt
            catchphrases {
              data {
                catchphrases
              }
            }
            photos {
              ext
              alternativeText
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_PHOTO_GALLERY {
          id
          photos {
            ext
            alternativeText
            url
            formats {
              large {
                url
              }
              medium {
                url
              }
              small {
                url
              }
              thumbnail {
                url
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_IFRAME {
          id
          url
          ratio
          pageSize: size
        }
        ... on STRAPI__COMPONENT_UI_CAROUSEL {
          id
          title {
            content
            secondary
            level
          }
          carouselPhotos: Photos {
            alternativeText
            ext
            url
          }
        }
        ... on STRAPI__COMPONENT_UI_TEXT_PHOTO {
          id
          textPhotoText: text {
            text {
              data {
                text
              }
            }
          }
          title {
            content
            level
            secondary
          }
          images {
            url
            ext
            formats {
              large {
                url
              }
              medium {
                url
              }
              small {
                url
              }
              thumbnail {
                url
              }
            }
          }
          buttons {
            id
            label
            url
            variant
            align
          }
          Background
          layout
          style
        }
        ... on STRAPI__COMPONENT_UI_STEPS {
          id
          Block {
            Number
            title
            description {
              children {
                text
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_IMAGE_GALLERY {
          id
          image {
            ext
            alternativeText
            url
          }
        }
        ... on STRAPI__COMPONENT_PAGE_FAQ {
          title {
            content
            id
            level
          }
          faqContent: content {
            text {
              data {
                text
              }
            }
          }
          helps {
            id
            answer {
              data {
                answer
              }
            }
            question
            label
          }
          layout
        }
      }
    }
  }
`
export default Page
